import React from 'react';
import {Box,Stack} from '@mui/material'
import ConversionButtonA from "../../Components/ConversionButtonA";
import ConversionButtonB from "../../Components/ConversionButtonB";

function TopSection1(){

    return (
        <Box id="top" component="section" bgcolor="#D1EAFE" pt={15} pb={10}>
            
            <Box className='section-content' margin="0 auto" fontSize={{xs:20, md:45}} fontWeight="bold" textAlign={{xs:"center", md:"left"}}>

                <Box >
                    <Box component="span" fontSize={{xs:25, md:65}} mr={1}>助成金</Box>をフル活用して<br/>社員が輝くソーシャルグッドな会社へ。
                </Box>
                <Box fontSize={{xs:14, md:24}} mt={1}>助成金、どうせやるなら「フル活用」！</Box>

                <Stack flexDirection={{xs:"column-reverse", md:"column"}} >
                    <Box component="img" display={{xs:"none", md:"block"}} src={process.env.PUBLIC_URL+"/static/media/LP/mainvisual.png"} alt="mainvisual" width="100%" mt={5}></Box>
                    <Box component="img" display={{xs:"block", md:"none"}} src={process.env.PUBLIC_URL+"/static/media/LP/SF_mainvisual.png"} alt="mainvisual" width="100%" mt={5}></Box>
                    
                    <Stack flexDirection={{xs:"column", md:"row"}} fontSize={{xs:20, md:30}} justifyContent="space-evenly" alignItems="center" gap={2} mt={10}>
                        <ConversionButtonA>
                            無料<Box component="span" margin="0 4px" fontSize={{xs:18, md:20}}>で</Box>助成金診断<Box component="span" margin="0 4px" fontSize={{xs:18, md:20}}>する</Box>
                        </ConversionButtonA>
                        <ConversionButtonB>
                            無料<Box component="span" margin="0 4px" fontSize={{xs:18, md:20}}>で</Box>プロに相談<Box component="span" margin="0 4px" fontSize={{xs:18, md:20}}>する</Box>
                        </ConversionButtonB>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    )
}

export default TopSection1;