import React from 'react';
import {Box,Stack} from '@mui/material'

function TopSection5(){

    return (
        <Box component="section" backgroundColor="#4771DF" pb={10}>
            <Box className='section-content' textAlign="center">
                <Stack justifyContent="center" alignItems="center" position="relative" pb={20}>
                    <Box width="30%" height="calc(200px / 2)" sx={{clipPath:"polygon(0 0, 100% 0, 50% 100%)"}} backgroundColor="#F2F9FF" position="absolute" top={-5}></Box>
                </Stack>
                <Box className='section-content' textAlign="center" color="white" fontWeight="bold" mb={3}>
                    <Box fontSize={{xs:18, md:20}}>そのような<Box component="br" display={{xs:"block",md:"none"}}/><Box component="span" fontSize={{xs:25, md:45}} ml={1} mr={1}>会社づくりをサポート<Box component="br" display={{xs:"block",md:"none"}}/></Box>してくれるのが</Box>
                    <Box fontSize={{xs:25, md:45}}>助成金<Box component="span">です！</Box></Box>
                </Box>
                <Box component="img" display={{xs:"none", md:"block"}} src={process.env.PUBLIC_URL+"/static/media/LP/TOP_joseikin1.png"} alt="TOP_joseikin1" width="100%"></Box>
                <Box component="img" display={{xs:"block", md:"none"}} src={process.env.PUBLIC_URL+"/static/media/LP/SF_TOP_joseikin1.png"} alt="TOP_joseikin1" width="100%"></Box>
            </Box>
        </Box>
    )
}

export default TopSection5;