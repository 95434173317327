import React,{ useState } from 'react';
import {Box,Stack} from '@mui/material'

function TopSection23(){

    const [faqList,setFaqList] = useState([
        {isOpen:false, q:"個人事業主でもサービスを利用することはできますか？", a:
            <>
                <Box>はい、ご利用できます。</Box>
                <Box>雇用保険に加入している従業員がいれば、法人・個人に関係なく助成金申請の対象となりますので、サービスをご利用できます。</Box>
                <Box>また、NPO法人、医療法人、一般社団法人、有限会社なども同様にご利用できます。</Box>
            </>},
        {isOpen:false, q:"契約期間の縛りはありますか？", a:
            <>
                <Box>契約期間の縛りはありません。</Box>
                <Box>ご契約の単位は1ヶ月単位で、1ヶ月毎の自動更新となります。</Box>
                <Box>例）ご契約日が「5月25日」の場合、「5月25日～6月24日」が契約期間（サービス提供期間）となります。</Box>
            </>},
        {isOpen:false, q:"お支払い方法を教えてください。", a:"お支払方法は、クレジットカード払い・銀行引き落とし・銀行振り込みからお選びいただけます。"},
        {isOpen:false, q:"複数社で1つの申し込みで良いですか？", a:"助成金は会社毎に申請するため、各会社様毎にお申し込みいただく必要がございます。"},
        {isOpen:false, q:"助成金だけでなく、補助金も対応していただけますか？", a:
            <>
                <Box>はい。経済産業省管轄の補助金（下記）につきましても、TRIPORTにて申請サポートをしております。</Box>
                <Box>・事業再構築補助金</Box>
                <Box>・ものづくり補助金</Box>
                <Box>・IT導入補助金（ベンダー登録・ツール登録も含む）</Box>
                <Box>・小規模事業者持続化補助金</Box>
                <Box>厚生労働省管轄の助成金も経済産業省管轄の補助金も、お気軽にご相談ください。</Box>
            </>},
    ])

    return (
        <Box component="section" backgroundColor="#EBEBEB" pt={10} pb={10}>
            <Box className='section-content' >
                <Box textAlign="center" fontSize={{xs:20, md:30}} fontWeight="bold">よくある<Box component="span" fontSize={{xs:30, md:45}} ml={1}>ご質問</Box></Box>
                <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={5} mb={5}></Box>

                {faqList.map((item,index)=>{
                    return(
                        <Box key={index} borderRadius="20px" backgroundColor="white" fontSize={{xs:16, md:18}} fontWeight="bold" boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px" mb={2} sx={{cursor:"pointer"}} onClick={()=>{
                            item.isOpen=!item.isOpen
                            setFaqList([...faqList])  
                        }}>
                            <Stack flexDirection="row" backgroundColor={item.isOpen===false ? "inherit":"#D1EAFE"} borderRadius={item.isOpen===false ? "20px":"20px 20px 0 0"} p={3} >
                                <Box flex={1} >{item.q}</Box>
                                <Box color="#4771DF">{item.isOpen===false ? "＋":"×"}</Box>
                            </Stack>
                            {item.isOpen===true &&
                                <Box p={3} fontWeight="normal">{item.a}</Box>
                            }
                        </Box>
                    )
                })}
                

            </Box>
        </Box>
    )
}

export default TopSection23;