import React from 'react';
import {Box,Stack} from '@mui/material'

function TopSection3(){

    return (
        <Box component="section" backgroundColor="white" pt={10} pb={10}>
            <Box className='section-content' textAlign="center">
                <Box fontSize={{xs:24, md:45}} fontWeight="bold">助成金制度<Box component="span" pl={1} fontSize={{xs:20, md:30}}>とは？</Box></Box>
                <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={5} mb={5}></Box>

                <Box fontSize={{xs:14, md:18}}>助成金は雇用保険料が財源。働きやすい環境作りに取組む企業に、国（厚生労働省）が金銭的な支援をする制度です。</Box>

                <Stack fontSize={30} flexDirection={{sx:"column",md:"row"}} justifyContent="center" gap={3} mt={4}>

                    <Stack flexBasis="30%">
                        <Box fontSize={60} fontWeight="bold" color="#93B1FF">01</Box>
                        <Stack flex={1} boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px" borderRadius={3}>
                            <Box borderRadius="12px 12px 0 0" backgroundColor="#4771DF" color="white" fontWeight="bold" fontSize={14} p={5}>財源は、<Box component="span" fontSize={25} color="white" ml={1}>雇用保険料</Box></Box>
                            <Stack flex={1} alignItems="center" >
                                <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_system01.png"} alt="TOP_system01" width={130} mt={1}></Box>
                                <Box flex={1} fontSize={14} p={2} textAlign="left">
                                    <Stack flexDirection="row" gap={1}>
                                        <Box width={10} height={10} borderRadius={5} backgroundColor="#4771DF" mt={1}></Box>
                                        <Box flex={1}>助成金の財源は、<Box component="span" fontWeight="bold">各企業が国に支払っている雇用保険料。</Box></Box>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Stack>
                    </Stack>

                    <Stack flexBasis="30%">
                        <Box fontSize={60} fontWeight="bold" color="#93B1FF">02</Box>
                        <Stack flex={1} boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px" borderRadius={3}>
                            <Box borderRadius="12px 12px 0 0" backgroundColor="#4771DF" color="white" fontWeight="bold" fontSize={14} p={5}>企業に<Box component="span" fontSize={25} color="white" ml={1} mr={1}>還元</Box>するしくみ</Box>
                            <Stack flex={1} alignItems="center">
                                <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_system02.png"} alt="TOP_system02" width={130} mt={1}></Box>
                                <Box flex={1} fontSize={14} p={2}  textAlign="left">
                                    <Stack flexDirection="row" gap={1} >
                                        <Box width={10} height={10} borderRadius={5} backgroundColor="#4771DF" mt={1}></Box>
                                        <Box flex={1}>国が集めた雇用保険料を、<Box component="span" fontWeight="bold">全国の企業に還元</Box>するしくみが、助成金。</Box>
                                    </Stack>
                                    <Stack flexDirection="row" gap={1} >
                                        <Box width={10} height={10} borderRadius={5} backgroundColor="#4771DF" mt={1}></Box>
                                        <Box flex={1}>雇用保険料を支払っている企業は、<Box component="span" fontWeight="bold">助成金をもらえるチャンス</Box>がある。</Box>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Stack>
                    </Stack>

                    <Stack flexBasis="30%">
                        <Box fontSize={60} fontWeight="bold" color="#93B1FF">03</Box>
                        <Stack flex={1} boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px" borderRadius={3}>
                            <Box borderRadius="12px 12px 0 0" backgroundColor="#4771DF" color="white" fontWeight="bold" fontSize={14} p={5}>目的は、<Box component="span" fontSize={25} color="white" ml={1}>生産性アップ</Box></Box>
                            <Stack flex={1} alignItems="center" >
                                <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_system03.png"} alt="TOP_system03" width={130} mt={1}></Box>
                                <Box flex={1} fontSize={14} p={2} textAlign="left">
                                    <Stack flexDirection="row" gap={1} >
                                        <Box width={10} height={10} borderRadius={5} backgroundColor="#4771DF" mt={1}></Box>
                                        <Box flex={1}>従業員にとって働きやすい環境を作り、生産性の高い会社をつくることが助成金の目的。</Box>
                                    </Stack>
                                    <Stack flexDirection="row" gap={1} >
                                        <Box width={10} height={10} borderRadius={5} backgroundColor="#4771DF" mt={1}></Box>
                                        <Box flex={1}>助成金活用で、<Box component="span" fontWeight="bold">従業員が働きやすく生産性の高い会社づくり</Box>ができる。</Box>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Stack>
                    </Stack>

                    

                    {/* <Stack flexBasis="30%">
                        <Box fontSize={60} color="#93B1FF">02</Box>
                        <div style={{border:"1px solid #4771DF", borderRadius:15,flex:1}}>
                            <div style={{borderRadius:"15px 15px 0 0",backgroundColor:"#4771DF",color:"white",fontSize:14,padding:30}}>企業に<span style={{fontSize:25,color:"white"}}>還元</span>するしくみ</div>
                            <div>
                                <img src={process.env.PUBLIC_URL+"/static/media/LP/TOP_system02.png"} alt="TOP_system02" style={{width:130}}></img>
                                <div style={{fontSize:14,padding:20,paddingTop:0,textAlign:"left"}}>
                                    <div>国が集めた雇用保険料を、全国の企業に還元するしくみが、助成金。</div>
                                    <div>雇用保険料を支払っている企業は、助成金をもらえるチャンスがある。</div>
                                </div>
                            </div>
                        </div>
                    </Stack> */}
                    {/* <Stack flexBasis="30%">
                        <Box fontSize={60} color="#93B1FF">03</Box>
                        <div style={{border:"1px solid #4771DF", borderRadius:15,flex:1}}>
                            <div style={{borderRadius:"15px 15px 0 0",backgroundColor:"#4771DF",color:"white",fontSize:14,padding:30}}>目的は、<span style={{fontSize:25,color:"white"}}>生産性アップ</span></div>
                            <div>
                                <img src={process.env.PUBLIC_URL+"/static/media/LP/TOP_system03.png"} alt="TOP_system03" style={{width:130}}></img>
                                <div style={{fontSize:14,padding:20,paddingTop:0,textAlign:"left"}}>
                                    <div>従業員にとって働きやすい環境を作り、生産性の高い会社をつくることが助成金の目的。</div>
                                    <div>助成金活用で、従業員が働きやすく生産性の高い会社づくりができる。</div>
                                </div>
                            </div>
                        </div>
                    </Stack> */}
                </Stack>
            </Box>
        </Box>
    )
}

export default TopSection3;