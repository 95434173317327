import React from 'react';
import {Box,Stack,Link} from '@mui/material'
import ConversionButtonA from "../../Components/ConversionButtonA";
import ConversionButtonB from "../../Components/ConversionButtonB";

function Footer() {

    const style_menu={
        textDecoration:"none",
        fontSize:15,
        color:"#393939",
    }

   

    return (
        <Box component="footer" bgcolor="#EBEBEB" p={5}>
            <Stack className='section-content' flexDirection={{sx:"column",md:"row"}} margin="0 auto" justifyContent="space-between" gap={1}>
                
                <Stack gap={2}>
                    <img src={process.env.PUBLIC_URL+"/static/media/LP/Logo.png"} alt="logo" width={150}></img>
                    <ConversionButtonA display={{xs:"none",md:"block"}} >無料で助成金診断</ConversionButtonA>
                    <ConversionButtonB display={{xs:"none",md:"block"}} >無料でプロに相談</ConversionButtonB>
                </Stack>

                <Stack flex={1} alignItems={{xs:"start",md:"end"}}>
                    <Stack flex={1} flexDirection={{xs:"column",md:"row"}} gap={{xs:2, md:20}} justifyContent={{xs:"center",md:"end"}}>
                        
                        {/* <Stack gap={1}>
                            <Link sx={style_menu}>助成金コーディネート</Link>
                            <Link sx={style_menu}>サービス内容</Link>
                            <Link sx={style_menu}>ご利用までの流れ</Link>
                        </Stack>
                        <Stack gap={1}>
                            <Link sx={style_menu}>運営会社</Link>
                            <Link sx={style_menu}>私たちの想い</Link>
                            <Link sx={style_menu}>FAQ</Link>
                        </Stack> */}
                        <Stack gap={1}>
                            <Link sx={style_menu} href="https://triport.co.jp/" target='_blank'>会社情報</Link>
                            <Link sx={style_menu} href="https://triport.co.jp/privacy/" target='_blank'>プライバシーポリシー</Link>
                            <Link sx={style_menu} href="https://jcoordinate.triport.co.jp/legal-compliance" target='_blank'>法令遵守について</Link>
                        </Stack>
                    </Stack>
                    <Box mt={1}>© 2024 TRIPORT Co.,Ltd. All rights reserved.</Box>
                </Stack>

                
            </Stack>
            
        </Box>
    );
}

export default Footer;