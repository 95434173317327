import './App.css';
import { BrowserRouter,Routes,Route } from "react-router-dom";

import Top from "./Pages/LP/Top"
import Customer1 from "./Pages/LP/Customer1"
import Customer2 from "./Pages/LP/Customer2"
import Customer3 from "./Pages/LP/Customer3"

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<Top/>}/>
          
          <Route path={"/customers/nicoichi"} element={<Customer1/>}/>
          <Route path={"/customers/criacao"} element={<Customer2/>}/>
          <Route path={"/customers/overcome-music"} element={<Customer3/>}/>
          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
