import React from 'react';
import {Box,Stack} from '@mui/material'

function TopSection21(){

    

    return (
        <Box component="section" backgroundColor="#D1EAFE" pt={10} pb={10}>
            <Box className='section-content' >
                <Box textAlign="center" fontSize={{xs:20, md:30}} fontWeight="bold">助成金コーディネートの<Box component="br" display={{xs:"block",md:"none"}}/><Box component="span" fontSize={{xs:30, md:45}} ml={1}>Mission</Box></Box>
                <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={5} mb={5}></Box>

                <Stack flexDirection={{sx:"column",md:"row"}} backgroundColor="white" borderRadius={5} p={2} gap={2}>
                    
                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_vision1.png"} alt="TOP_vision1" width="100%" maxWidth={500}></Box>
                    
                    <Stack flex={1} >
                        <Box fontSize={{xs:16, md:20}} fontWeight="bold" textAlign={{xs:"center", md:"left"}}>日本の会社を、<Box component="br" display={{xs:"block",md:"none"}}/><Box component="span" fontSize={{xs:20, md:30}}>「守る」×「強くする」</Box></Box>
                        <Box fontSize={{xs:14, md:14}} flex={1} mt={2}>
                            <Box>助成金は、お金を貰うためだけの制度ではありません。会社がお金のために無理をして制度に合わせるものでもないのです。</Box>
                            <Box>国の指針に基づき、働く人の環境を整える企業を応援する制度なのです。</Box>

                            <Box mt={2}>助成金は、会社を労務リスクから「守り」、さらには、</Box>
                            <Box>生産性が上がるサステナブルな「強い」組織づくりを後押しする制度です。</Box>

                            <Box mt={2}>もらえるはずの助成金を活用しながら、日本全国の会社を</Box>
                            <Box>「守り」、「強くする」こと。これが、私たちのミッションです。</Box>
                        </Box>
                    </Stack>
                </Stack>

                <Stack flexDirection={{sx:"column",md:"row"}} backgroundColor="white" borderRadius={5} p={2} gap={2} mt={5}>
                    
                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_vision1-1.png"} alt="TOP_vision1-1" width="100%" maxWidth={500}></Box>
                    
                    <Stack flex={1} >
                        <Box fontSize={{xs:16, md:20}} fontWeight="bold" textAlign={{xs:"center", md:"left"}}>
                            <Box>会社の財産である<Box component="span" fontSize={{xs:20, md:30}}>「人材」</Box>を活かし</Box>
                            <Box><Box component="span" fontSize={{xs:20, md:30}} mr={1}>サステナブル</Box>な<Box component="span" fontSize={{xs:20, md:30}} ml={1} mr={1}>会社づくり</Box>に<Box component="span" fontSize={{xs:20, md:30}} ml={1} mr={1}>貢献</Box>する</Box>
                        </Box>
                        <Box flex={1} mt={2}>労務は、社員が働きやすい環境を作り、問題のない職場を構築することです。TRIPORTは、企業が成長し、社員が意欲的に働ける環境を創り出すお手伝いをいたします。</Box>
                    </Stack>
                </Stack>

                {/* <Stack alignItems="center" mt={10}>
                    <Link display="inline-block" backgroundColor="white" sx={{color:"#4771DF"}} fontWeight="bold" textAlign="center" mt={2} p={2} pl={10} pr={10} fontSize={30} border="3px solid #4771DF" borderRadius={10}>私たちの想い<Box component="span" fontSize={20}>を</Box>もっと見る</Link>
                </Stack> */}

            </Box>
        </Box>
    )
}

export default TopSection21;