import React from 'react';
import {Box,Stack} from '@mui/material'

function TopSection6(){

    return (
        <Box component="section" backgroundColor="white" textAlign="center" pt={10} pb={10}>
            <Box className='section-content' textAlign="center">

                <Stack flexDirection="row" justifyContent="space-evenly" mb={5}>
                    <Box >
                        <Box fontSize={{xs:20, md:30}} fontWeight="bold" backgroundColor="#D1EAFE" padding="10px 40px " borderRadius={5}>助成金の「フル活用」で</Box>
                        <Stack justifyContent="center" alignItems="center" position="relative" >
                            <Box width="30px" height="calc(60px / 2)" sx={{clipPath:"polygon(0 0, 100% 0, 50% 100%)"}} backgroundColor="#D1EAFE" position="absolute" bottom={-20}></Box>
                        </Stack>
                    </Box>
                </Stack>

                <Box fontSize={{xs:16, md:30}} fontWeight="bold">従業員が輝く<Box component="br" display={{xs:"block",md:"none"}}/><Box component="span" fontSize={{xs:20, md:45}} color="#ED346C" mr={1}>ソーシャルグッド<Box component="span" fontSize={{xs:16, md:30}} mr={1} ml={1}>な</Box >経営サイクル</Box>を<br/>実現しませんか？</Box>
                <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={5} mb={5}></Box>

                <Box backgroundColor="#D1EAFE" borderRadius={5} p={5}>
                    {/* <img src={process.env.PUBLIC_URL+"/static/media/LP/TOP_cycle.png"} alt="TOP_cycle" style={{width:"100%"}}></img> */}
                    <Box component="img" display={{xs:"none", md:"block"}} src={process.env.PUBLIC_URL+"/static/media/LP/TOP_cycle.png"} alt="TOP_cycle" width="100%"></Box>
                    <Box component="img" display={{xs:"block", md:"none"}} src={process.env.PUBLIC_URL+"/static/media/LP/SF_TOP_cycle.png"} alt="TOP_cycle" width="100%"></Box>
                </Box>
            </Box>

        </Box>
    )
}

export default TopSection6;