import React from 'react';
import {Box,Stack} from '@mui/material'
import ConversionButtonA from "../../Components/ConversionButtonA";
import ConversionButtonB from "../../Components/ConversionButtonB";

function TopSection13(){

    

    return (
        <Box component="section" backgroundColor="#D1EAFE" pb={10}>
            <Box className='section-content' >
                <Stack justifyContent="center" alignItems="center" position="relative" pb={20}>
                    <Box width="30%" height="calc(200px / 2)" sx={{clipPath:"polygon(0 0, 100% 0, 50% 100%)"}} backgroundColor="#4771DF" position="absolute" top={-5}></Box>
                </Stack>
                <Box className='section-content' textAlign="center" fontWeight="bold" mb={10}>
                    <Box fontSize={{xs:20, md:37}}>「え？！うちの会社、<br/>もっともらえるはずだったの！？」</Box>
                    <Box fontSize={{xs:16, md:30}}>後悔する前に、まずはお気軽にご相談ください。</Box>
                </Box>

                <Stack flexDirection={{sx:"column",md:"row"}} backgroundColor="white" borderRadius={5} p={{xs:2,md:10}}>
                    <Stack flex={1} justifyContent="space-evenly" gap={1}>
                        <Stack flexDirection="row" gap={1} alignItems="center">
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/checkbox3.png"} alt="checkbox3" width="100%" maxWidth={30}></Box>
                            <Box fontSize={{xs:16, md:20}} fontWeight="bold" flex={1}>助成金について<Box component="br" display={{xs:"block",md:"none"}}/>１から教えてほしい！</Box>
                        </Stack>
                        <Stack flexDirection="row" gap={1} alignItems="center">
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/checkbox3.png"} alt="checkbox3" width="100%" maxWidth={30}></Box>
                            <Box fontSize={{xs:16, md:20}} fontWeight="bold" flex={1}>自分の会社が使える助成金が<Box component="br" display={{xs:"block",md:"none"}}/>知りたい！</Box>
                        </Stack>
                        <Stack flexDirection="row" gap={1} alignItems="center">
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/checkbox3.png"} alt="checkbox3" width="100%" maxWidth={30}></Box>
                            <Box fontSize={{xs:16, md:20}} fontWeight="bold" flex={1}>今のうちから、<Box component="br" display={{xs:"block",md:"none"}}/>できることを教えて欲しい！</Box>
                        </Stack>
                        <Stack flexDirection="row" gap={1} alignItems="center">
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/checkbox3.png"} alt="checkbox3" width="100%" maxWidth={30}></Box>
                            <Box fontSize={{xs:16, md:20}} fontWeight="bold" flex={1}>助成金を使ったことはあるが、<Box component="br" display={{xs:"block",md:"none"}}/>他の助成金についても知りたい！</Box>
                        </Stack>
                    </Stack>
                    <Box width={300} textAlign="center" mt={2}>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/CTA3.png"} alt="CTA3" width="100%" maxWidth={160}></Box>
                    </Box>
                </Stack>

                <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="space-evenly" mt={10} textAlign="center" gap={3}>
                    <Box >
                        <Box fontSize={{xs:14, md:16}} fontWeight="bold" mb={1} color="#ED346C">自社が使えそうな助成金を診断してもらいたい</Box>
                        <ConversionButtonA display="block" fontSize={{xs:20, md:30}} >無料<Box component="span" ml={1} mr={1} fontSize={{xs:16, md:20}}>で</Box>助成金診断<Box component="span" ml={1} fontSize={{xs:16, md:20}}>する</Box></ConversionButtonA>
                    </Box>
                    <Box>
                        <Box fontSize={{xs:14, md:16}} fontWeight="bold" mb={1} color="#ED346C">助成金のプロから、直接話を聞きたい</Box>
                        <ConversionButtonB display="block" fontSize={{xs:20, md:30}} >無料<Box component="span" ml={1} mr={1} fontSize={{xs:16, md:20}}>で</Box>プロに相談<Box component="span" ml={1} fontSize={{xs:16, md:20}}>する</Box></ConversionButtonB>
                    </Box>
                </Stack>
                
            </Box>
        </Box>
    )
}

export default TopSection13;