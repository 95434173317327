import React from 'react';
import {Box,Stack} from '@mui/material'

function TopSection19(){

    

    return (
        <Box component="section" backgroundColor="#EBEBEB" pt={10} pb={10}>
            <Box className='section-content' >
                <Box textAlign="center" fontSize={{xs:20, md:30}} fontWeight="bold">導入効果</Box>
                <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={5} mb={5}></Box>

                <Stack flexDirection={{sx:"column",md:"row"}} p={2} gap={5}>
                    <Stack flex={1} justifyContent="space-around" gap={2}>
                        
                        <Stack flexDirection="row" gap={2} alignItems="start">
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/checkbox1.png"} alt="checkbox1" width="100%" maxWidth={30}></Box>
                            <Box fontSize={{xs:14, md:16}} fontWeight="bold">安心して採用できるようになり、<br/><Box component="span" fontSize={{xs:20, md:20}}>従業員数</Box>が<Box component="span" fontSize={{xs:20, md:20}}>２倍</Box>に！</Box>
                        </Stack>

                        <Stack flexDirection="row" gap={2} alignItems="start">
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/checkbox1.png"} alt="checkbox1" width="100%" maxWidth={30}></Box>
                            <Box fontSize={{xs:14, md:16}} fontWeight="bold">何役も兼務していた社長の<Box component="br" display={{xs:"block",md:"none"}}/><Box component="span" fontSize={{xs:20, md:20}}>業務負担が軽減</Box>され、<br/><Box component="span" fontSize={{xs:20, md:20}}>本業に専念</Box>でできるようになった！</Box>
                        </Stack>

                        <Stack flexDirection="row" gap={2} alignItems="start">
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/checkbox1.png"} alt="checkbox1" width="100%" maxWidth={30}></Box>
                            <Box fontSize={{xs:14, md:16}} fontWeight="bold">従業員の<Box component="br" display={{xs:"block",md:"none"}}/><Box component="span" fontSize={{xs:20, md:20}}>働きやすい環境</Box>を<Box component="br" display={{xs:"block",md:"none"}}/>作ることができた！</Box>
                        </Stack>

                        <Stack flexDirection="row" gap={2} alignItems="start">
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/checkbox1.png"} alt="checkbox1" width="100%" maxWidth={30}></Box>
                            <Box fontSize={{xs:14, md:16}} fontWeight="bold">従業員のためになる制度などを導入できた上、助成金も受給！<br/>本当の意味で<Box component="span" fontSize={{xs:20, md:20}}>会社の成長を支える土台作り</Box>ができた！</Box>
                        </Stack>

                        <Stack flexDirection="row" gap={2} alignItems="start">
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/checkbox1.png"} alt="checkbox1" width="100%" maxWidth={30}></Box>
                            <Box fontSize={{xs:14, md:16}} fontWeight="bold">会社の<Box component="span" fontSize={{xs:20, md:20}}>理想とする労働環境を実現</Box>できた！</Box>
                        </Stack>
                       
                    </Stack>
                    <Box backgroundColor="white" borderRadius={5} pt={5} pb={5}>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_effect1.png"} alt="TOP_effect1" width="100%" maxWidth={500}></Box>
                    </Box>
                </Stack>

                
            </Box>
        </Box>
    )
}

export default TopSection19;