import React from 'react';
import {Link} from '@mui/material'
import { useSearchParams } from "react-router-dom";


function ConversionButtonA(props) {

    
    const [searchParams] = useSearchParams();
    
    const baseUrl = "https://script.google.com/a/macros/triport.co.jp/s/AKfycbxGyuF217FEJj3joX5c_N6ySk_TG1WLRfj6B6dDaN5TWAv0MVTVIESrgfYiJ_8TYkoSxQ/exec"

    let partnerId = searchParams.get("id");
    let campainId = searchParams.get("ca");

    if(partnerId == null){
        partnerId = "8f70b68dfe86450fad06adf41c8f4fb2"
    }

    let targetUrl = `${baseUrl}?p=${partnerId}`;

    if(campainId!=null){
        targetUrl += `&ca=${campainId}`
    }
    
    return (
        <Link 
            display={props.display} 
            color="#ED346C" 
            borderRadius={50} 
            fontSize={props.fontSize} 
            backgroundColor="white" 
            border="solid 3px #ED346C" 
            p="10px 30px" 
            fontWeight="bold" 
            href={targetUrl} 
            target={props.target}
            sx={{textDecoration:"none"}}>{props.children}</Link>
    );
}

ConversionButtonA.defaultProps = {
    target:"_blank"
}

export default ConversionButtonA;