import React from 'react';
import {Box,Stack} from '@mui/material'

function TopSection10(){

    

    return (
        <Box component="section" backgroundColor="#EBEBEB" pt={10} pb={10}>
            <Box className='section-content' textAlign="center">
                <Box fontSize={{xs:16, md:20}} fontWeight="bold">ノウハウ・ナレッジがないまま</Box>
                <Box fontSize={{xs:20, md:30}} fontWeight="bold" mt={2}>助成金申請するときに<br/>よくぶつかる<Box component="span" fontSize={{xs:30, md:45}} pl={1}>３つのハードル</Box></Box>
                <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={5} mb={5}></Box>

                <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="center" gap={3}>

                    <Stack alignItems="center" flexBasis="30%">
                        <Stack justifyContent="center" alignItems="center" mb={-6} zIndex={1} width={100} height={100} borderRadius={50} backgroundColor="white">
                            <Box  fontSize={50} fontWeight="bold" color="#4771DF">01</Box>
                        </Stack>
                        <Stack alignItems="center" backgroundColor="white" borderRadius={3} p={2} pt={6} flex={1} width="100%">
                            <Box color="#4771DF" fontWeight="bold" mb={2}>
                                <Box fontSize={14}>使える<Box component="span" fontSize={22} pl={1} pr={1}>助成金</Box>があることを</Box>
                                <Box fontSize={30}>知らない</Box>
                            </Box>
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_hurdle1.png"} alt="TOP_hurdle1" width="100%" maxWidth={200}></Box>
                            <Stack flex={1} alignItems="start" mt={2} textAlign="left" width="100%">
                                <Stack flexDirection="row" gap={1} >
                                    <Box width={10} height={10} borderRadius={5} backgroundColor="#4771DF" mt={1}></Box>
                                    <Box flex={1} fontSize={14}>自分の会社で使える助成金があることを知らなかった…</Box>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>

                    <Stack alignItems="center" flexBasis="30%">
                        <Stack justifyContent="center" alignItems="center" mb={-6} zIndex={1} width={100} height={100} borderRadius={50} backgroundColor="white">
                            <Box  fontSize={50} fontWeight="bold" color="#4771DF">02</Box>
                        </Stack>
                        <Stack alignItems="center" backgroundColor="white" borderRadius={5} p={2} pt={6} flex={1} width="100%">
                            <Box color="#4771DF" fontWeight="bold" mb={2}>
                                <Box fontSize={14}>スケジュール<Box component="span" fontSize={22} pl={1} pr={1}>管理、法令遵守</Box>が</Box>
                                <Box fontSize={30}>できない</Box>
                            </Box>
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_hurdle2.png"} alt="TOP_hurdle2" width="100%" maxWidth={200}></Box>
                            <Stack flex={1} justifyContent="start" alignItems="start" mt={2} textAlign="left" width="100%">
                                <Stack flexDirection="row" gap={1} >
                                    <Box width={10} height={10} borderRadius={5} backgroundColor="#4771DF" mt={1}></Box>
                                    <Box flex={1} fontSize={14}>事前準備、スケジュール管理が大変…</Box>
                                </Stack>
                                <Stack flexDirection="row" gap={1} >
                                    <Box width={10} height={10} borderRadius={5} backgroundColor="#4771DF" mt={1}></Box>
                                    <Box flex={1} fontSize={14}>法令遵守できていなかった…</Box>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>

                    <Stack alignItems="center" flexBasis="30%">
                        <Stack justifyContent="center" alignItems="center" mb={-6} zIndex={1} width={100} height={100} borderRadius={50} backgroundColor="white">
                            <Box  fontSize={50} fontWeight="bold" color="#4771DF">03</Box>
                        </Stack>
                        <Stack alignItems="center" backgroundColor="white" borderRadius={5} p={2} pt={6} flex={1} width="100%">
                            <Box color="#4771DF" fontWeight="bold" mb={2}>
                                <Box fontSize={14}>助成金に精通した<Box component="span" fontSize={22} pl={1} pr={1}>専門家</Box>が、身近に</Box>
                                <Box fontSize={30}>いない</Box>
                            </Box>
                            <Box component="img"  src={process.env.PUBLIC_URL+"/static/media/LP/TOP_hurdle3.png"} alt="TOP_hurdle3" width="100%" maxWidth={200}></Box>
                            <Stack flex={1} alignItems="start" mt={2} textAlign="left" width="100%">
                                
                                <Stack flexDirection="row" gap={1} >
                                    <Box width={10} height={10} borderRadius={5} backgroundColor="#4771DF" mt={1}></Box>
                                    <Box flex={1} fontSize={14}>顧問社労士に対応してもらえかなった…（社労士の中でも助成金のプロはわずか1%*）</Box>
                                </Stack>
                                <Stack flexDirection="row" gap={1} >
                                    <Box width={10} height={10} borderRadius={5} backgroundColor="#4771DF" mt={1}></Box>
                                    <Box flex={1} fontSize={14}>ノウハウのない自社で内製化は難しい…</Box>
                                </Stack>
                                <Box mt={2} textAlign="left" fontSize={11}>*社労士の中でも「あらゆる助成金をフル活用できる社労士」の割合</Box>
                            </Stack>
                        </Stack>
                    </Stack>
                    
                </Stack>
                
            </Box>
        </Box>
    )
}

export default TopSection10