import React,{ useState } from 'react';
import {Box,Stack,Link, Menu} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import ConversionButtonA from "../../Components/ConversionButtonA";
import ConversionButtonB from "../../Components/ConversionButtonB";

function Header() {

    const style_menu={
        textDecoration:"none",
        fontWeight:"bold"
    }

    const [isOpen,setIsOpen] = useState(false);

    return (
        <Box component="header" position="fixed" left={0} right={0} top={0} backgroundColor="#fffffff5" zIndex={999}>
            <Stack className='section-content' flexDirection="row" margin="0 auto" justifyContent="space-between" alignItems="center" gap={1} p={1}>
                <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/Logo.png"} alt="logo" width={140}></Box>
                
                {/* <Stack display={{xs:"none",md:"flex"}} flexDirection="row" gap={2}>
                    <Link sx={style_menu} href="#top">TOP</Link>
                    <Link sx={style_menu}>サービス内容</Link>
                    <Link sx={style_menu}>ご利用までの流れ</Link>
                    <Link sx={style_menu}>運営会社</Link>
                    <Link sx={style_menu}>私たちの想い</Link>
                    <Link sx={style_menu}>FAQ</Link>
                </Stack> */}

                <Stack display={{xs:"none",md:"flex"}} flexDirection="row" gap={1}>
                    <ConversionButtonA>無料で助成金診断</ConversionButtonA>
                    <ConversionButtonB>無料でプロに相談</ConversionButtonB>
                </Stack>

                {/* <Box display={{xs:"flex",md:"none"}} onClick={()=>{setIsOpen(!isOpen)}}>
                    <MenuIcon></MenuIcon>
                </Box> */}
            </Stack>
            {/* {isOpen &&
                <Stack flexDirection="column" position="absolute" left={0} right={0} gap={3} backgroundColor="#fffffff5" alignItems="center" p={2}>
                    <Link sx={style_menu} href="#top" onClick={()=>{setIsOpen(!isOpen)}}>TOP</Link>
                    <Link sx={style_menu} onClick={()=>{setIsOpen(!isOpen)}}>サービス内容</Link>
                    <Link sx={style_menu} onClick={()=>{setIsOpen(!isOpen)}}>ご利用までの流れ</Link>
                    <Link sx={style_menu} onClick={()=>{setIsOpen(!isOpen)}}>運営会社</Link>
                    <Link sx={style_menu} onClick={()=>{setIsOpen(!isOpen)}}>私たちの想い</Link>
                    <Link sx={style_menu} onClick={()=>{setIsOpen(!isOpen)}}>FAQ</Link>
                </Stack>
            } */}
            
        </Box>
        
    );
}

export default Header;